import React from "react"

import crunchy_rolls_slika1 from "../images/products/crunchy_rolls_slika1.jpg"
import crunchy_rolls_slika2 from "../images/products/crunchy_rolls_slika2.jpg"

class CrunchyRolls extends React.Component {
  render() {
    return (
      <section id="proizvodi" className="hero products-page default-page">
        <div className="container padding-0 margin-0">
          <div className="columns margin-0 main">
            <div className="column is-4 static-part is-left padding-0">
              <div className="content">
                <div className="title">CRUNCHY ROLLS</div>
                <span className="zigzag"></span>
                <p className="text">
                  Nova dimenzija ukusa smeštena je unutar Crunchy Rolls, rolnica
                  koje šire pozitivnu energiju, okupljaju stare i povezuju nove
                  potrošače.
                </p>
              </div>
            </div>
            {/*STATIC PART END */}
            <div className="column is-8 content-part is-right padding-0">
              <div className="columns is-multiline inner margin-0">
                <div className="column is-12 single-product padding-0">
                  <img
                    src={crunchy_rolls_slika1}
                    alt="Crunchy rolls - vanilla"
                  />
                </div>
                <div className="column is-12 single-product padding-0">
                  <img src={crunchy_rolls_slika2} alt="Crunchy rolls - coco" />
                </div>
              </div>
            </div>
            {/* PRODUCTS PART END */}
          </div>
          {/* COLUMNS END */}
        </div>
        {/* CONTAINER END */}
      </section> /* PRODUCTS PAGE SECTION END */
    )
  }
}

export default CrunchyRolls
